;(function(){
	var Modal = $('.overlay');

  var init = function () {
    if(!Modal.length){
      $('.main-wrap').append('<div class="overlay"></div>');
      Modal = $('.overlay');
    }

    var load = function (options, cb) {
      $.ajax(options)
        .done(function (res) {
          cb(res);
          Modal.trigger('modal:opened');
        })
        .fail(function () {
          Alert('Ocorreu um erro...', {type: 'error'});
          Modal.close();
        })
        .complete(function () {
          Modal.removeClass('loading');
        });
    };

    var render = function (html) {
      Modal.html(html);
    };

    Modal.open = function (url, options) {
      if(typeof url == 'object'){
        options = url;
        url = options.url;
      }
      options = clone({
        url: url,
        type: 'get',
        dataType: 'html',
        done: render
      }, options);

      options = clone(options, {
        success: undefined,
        error: undefined,
        complete: undefined
      });

      Modal.addClass('open loading');
      load(options, options.done);
    };

    Modal.clear = function () {
      var modal = Modal.find('.modal');
      modal.animate({opacity: 0}, 300, 'swing', function () {
        modal.remove();
        Modal.addClass('loading');
      })
    };

    Modal.close = function () {
      Modal.removeClass('open');
      setTimeout(function () {
        Modal.html('');
      }, 300);
    };

    Modal.on('click', function (e) {
      var target = $(e.target);
      if(target.closest('.close-modal').length || !target.closest('.modal').length){
        e.preventDefault();
        Modal.close();
      }
    });

    Modal.on('submit', 'form.ajax-form', function (e) {
      e.preventDefault();

      var form = $(this);
      Modal.open({
        url: form.attr('action'),
        type: 'post',
        data: form.serialize()
      });
    });

    setTimeout(function () {
      Modal.toggleClass('open', !!Modal.children('.modal').length)
    }, 500);
  };

  init();

  window.Modal = Modal;
})();