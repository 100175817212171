;(function () {
    var Page = $('.page-adaptation');
    var Carousel = Page.find('.calendars .stage');

    var config = {
        margin: 30,
        width: 308
    };

    var between = function (x, min, max) {
        return x >= min && x <= max;
    };

    var ajax = function (dir, _last) {
        _last = _last || false;
        Carousel.translating = true;
        var list = Carousel.stage.find('.list');
        var conf = {
            url: site_config.base_url + 'de-olho-na-adaptacao/calendario/',
            type: 'get',
            dataType: 'html'
        };
        var year, month;
        switch (dir) {
            case 'next':
                var last = list.find('.car-item:last-child [data-month][data-year]');
                month = parseInt(last.data('month')) + 1;
                year = parseInt(last.data('year'));
                if (month > 12) {
                    month = month - 12;
                    year++;
                }
                conf.url += year + '-' + month;
                break;
            case 'prev':
                var first = list.find('.car-item:first-child [data-month][data-year]');
                month = parseInt(first.data('month')) - 1;
                year = parseInt(first.data('year'));
                if (month < 1) {
                    month = 12 + month;
                    year--;
                }
                var url = '/' + year + '-' + month;
                month -= 2;
                if (month < 1) {
                    month = 12 + month;
                    year--;
                }
                conf.url += year + '-' + month + url;
                break;
        }

        list.addClass('loading');
        $.ajax(conf)
            .done(function (html) {
                list.html(html);
                Carousel.init();
                list.css({transform: 'translate3d(0,0,0)'});
                setTimeout(function () {
                    var i = 0;
                    do {
                        Carousel.refresh();
                        i++;
                    } while (!Carousel.stage.find('.car-item.visible').length && i < 10);
                    if (_last && $(window).width() < 1064) {
                        Carousel.next(2);
                    }
                }, 250);
            })
            .fail(function () {
                Carousel.translating = false;
            })
            .always(function () {
                list.removeClass('loading');
            })
    };

    var calcMatrix = function (elem) {
        return elem.css('transform').replace(/[^0-9,\-]/g, '').split(',').map(function (el) {
            return parseFloat(el);
        });
    };

    var isVisible = function (elem) {
        var offset = Carousel.stage.offset().left;
        var left = elem.offset().left - offset;
        var right = left + elem.width();
        var full = Carousel.stage.width();

        return between(right, full - 10, full + 10) && between(left, -10, 10);
    };

    Carousel.translate = function (list, x) {
        list.css({
            'transform': 'translate3d(' + x + 'px,0,0)',
            '-webkit-transform': 'translate3d(' + x + 'px,0,0)',
            '-moz-transform': 'translate3d(' + x + 'px,0,0)',
            '-ms-transform': 'translate3d(' + x + 'px,0,0)',
            '-o-transform': 'translate3d(' + x + 'px,0,0)'
        });
        setTimeout(function () {
            Carousel.refresh();
            Carousel.translating = false;
        }, 250);
    };

    Carousel.next = function (times) {
        times = times || 1;
        var next = Carousel.stage.find('.car-item.visible').last().next();
        var list = Carousel.stage.find('.list');
        var x = calcMatrix(list)[4];
        var gap = config.width + config.margin;
        var result = (x - gap) * times;

        if (next.length) {
            Carousel.translate(list, result);
        } else {
            ajax('next');
        }
    };

    Carousel.prev = function (noajax) {
        noajax = noajax || false;
        var prev = Carousel.stage.find('.car-item.visible').first().prev();
        var list = Carousel.stage.find('.list');
        var x = calcMatrix(list)[4];
        var gap = config.width + config.margin;
        var result = x + gap;

        if (prev.length) {
            Carousel.translate(list, result);
        } else if (noajax === false) {
            ajax('prev', true);
        }
    };

    Carousel.refresh = function () {
        var win_W = Math.min(Carousel.width(), ((config.width + config.margin) * 3 - (config.margin)));
        var shown = Carousel.itemsShown = Math.max(Math.floor(win_W / (config.width + ((config.margin / 3) * 2))), 1);
        Carousel.perTime = 3 - shown;
        Carousel.stage.width((shown * config.width) + ((shown - 1) * config.margin));

        var list = Carousel.stage.find('.list');
        list.find('.car-item').each(function (i, el) {
            var $el = $(el);
            $el.toggleClass('visible', isVisible($el));
        });
        var active = list.find('.car-item:has(.active).visible');

        if (list.find('.car-item.visible').length < shown) {
            Carousel.prev(true);
        }

        if(!active.is('.visible')){
            var i = list.find('.car-item.visible').index();
            if(i > active.index()) {
                Carousel.next(true);
            }else{
                Carousel.prev(true);
            }
        }
    };

    Carousel.addEvents = function () {
        // Responsiveness
        $(window).on('resize', function () {
            Carousel.refresh();
        });

        // Navigation
        Carousel.controls.on('mousedown touchend', '> *', function (e) {
            e.preventDefault();
            var btn = $(this);
            if (Carousel.translating) {
                return false;
            }

            Carousel.translating = true;
            if (btn.is('.prev')) {
                Carousel.prev();
            } else if (btn.is('.next')) {
                Carousel.next();
            }
        });

        Carousel.find('.controls').hide();
    };

    Carousel.init = function () {
        Carousel.translating = false;
        Carousel.stage = Carousel.children('.stage-outer');
        Carousel.controls = Carousel.children('.controls');
        Carousel.itemsShown = Math.floor(Carousel.width() / config.width);
        Carousel
            .find('.list')
            .width((config.width * 3) + (config.margin * 3))
            .children()
            .wrap('<div class="car-item" style="padding-right: ' + config.margin + 'px;"></div>');
        Carousel.refresh();
    };

    Page.init = function () {
        if (Page.length) {
            Carousel.init();
            Carousel.addEvents();
        }
    };

    Page.init();
})();
