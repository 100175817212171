;(function(){
  var Period;

  var init = function () {
    Period = $('.modal-period');

    Period.on('click', '.btn', function (e) {
      e.preventDefault();

      var btn = $(this);
      var map = $.map(btn.children('.hidden'), function (el) {
        var $el = $(el);
        return {
          href: $el.data('photo'),
          title: $el.html()
        };
      });

      $.fancybox.open(map, {
        helpers: {
          thumbs: {
            width: 80,
            height: 80
          }
        }
      });
      Modal.close();
    });
  };
  Modal.on('modal:opened', init);
})();