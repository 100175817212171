;(function(){
    var Graduation = $('.page-graduation');

    Graduation.addEvents = function () {
        Graduation.find('.gallery .item .btn').on('click', function (e) {
            e.preventDefault();
            var item = $(this).closest('.item');

            if(!item.data('photos')){
                item.data('photos', item.find('[data-photo]'));
            }
            var items = item.data('photos');

            var map = $.map(items, function (el) {
                var $el = $(el);

                var obj = {
                    href: $el.data('photo'),
                    title: $el.html()
                };
                $el.removeAttr('data-photo');
                return obj;
            });

            $.fancybox.open(map, {
                helpers:{
                    thumbs: {
                        width: 80,
                        height: 80
                    }
                }
            });
        });
    };

    Graduation.init = function () {
        Graduation.addEvents();
    };

    Graduation.init();
})();