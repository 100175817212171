;(function(){
	var About = $('.page-about');
  var Structure = About.find('.structure');

  Structure.toggleTab = function (button) {
    var gallery = Structure.find('.gallery'+button.data('href'));

    gallery
      .addClass('active')
      .siblings('.gallery')
        .removeClass('active');

    button
      .addClass('active')
      .siblings('.btn')
        .removeClass('active');
  };

  About.addEvents = function () {
    Structure.on('click', '.btn', function (e) {
      e.preventDefault();
      Structure.toggleTab($(this));
    });

    Structure.find('.gallery .item').on('click', function (e) {
      e.preventDefault();

      var item = $(this);
      if(!item.data('photos')){
        item.data('photos', item.find('[data-photo]'));
      }
      var items = item.data('photos');

      var map = $.map(items, function (el) {
        var $el = $(el);

        var obj = {
          href: $el.data('photo'),
          title: $el.html()
        };
        $el.removeAttr('data-photo');
        return obj;
      });

      $.fancybox.open(map, {
        helpers:{
          thumbs: {
            width: 80,
            height: 80
          }
        }
      });
    });
  };

  About.init = function () {
    About.addEvents();
  };

  About.init();
})();