;(function(){
	var Footer = $('.main-footer');

  Footer.addEvents = function () {
    Footer.nav.on('click touchstart', 'li:first-child i', function (e) {
      e.preventDefault();

      var item = $(this).closest('li');
      var group = item.closest('ul');
      console.log(item, group);
      group
        .toggleClass('show')
        .siblings('.show').removeClass('show');
    })
  };

  Footer.init = function () {
    Footer.nav = Footer.find('.first-row ul');

    Footer.addEvents();
  };

  Footer.init();
})();