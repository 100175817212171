;(function(){
  var scope = {};

  window.isMobile = function () {
    return $(window).width() <= 520;
  };

  window.isTablet = function () {
    return $(window).width() <= 1024;
  };

  scope.addEvents = function () {
    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
      },
      spOptions = {
        onKeyPress: function(val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
      };

    $('input.phone').mask(SPMaskBehavior, spOptions);

    scope.container.on('click', '[data-social]', function (e) {
      e.preventDefault();
      var button = $(this);
      var href = button.attr('href');
      var url = 'https://';

      switch (button.data('social')) {
        case 'facebook':
          url += 'www.facebook.com/sharer/sharer.php?u='+href+'&display=popup';
          break;
        case 'twitter':
          url += 'twitter.com/share?url='+href+'&Lumitenzi!&related=vineapp';
          break;
        case 'google':
          url += 'plus.google.com/share?url='+href;
          break;
        default: return true;
      }

      var share = window.open(url, 'shareWindow', 'width=670,height=380');
      share.moveTo((screen.width - 670)/2, (screen.height - 380)/2);
    });

    scope.container.on('click', '[rel=modal]', function (e) {
      e.preventDefault();

      var $this = $(this);
      var href = $this.data('href') || $this.attr('href');
      var options = {};
      if(!href){
        var form = $this.closest('form');
        href = form.attr('action');
        options = {
          data: form.serialize(),
          type: 'post'
        }
      }

      Modal.open(href, options);
      return false;
    });

    scope.container.on('click', '[data-load-more]', function (e) {
      e.preventDefault();

      var btn = $(this);
      var target = scope.container.find(btn.data('load-more'));
      var href = btn.data('href') || btn.attr('href');

      btn.addClass('loading');
      $.ajax({
        url: href,
        type: 'get',
        dataType: 'json'
      }).done(function (res) {
        btn.attr('href', res.href);
        target.append(res.html);
        if(res.href == '#'){
          btn.remove();
        }
      }).always(function () {
        btn.removeClass('loading');
      });
    });

    var scrollToHash = function (hash) {
      $('html, body')
        .animate({scrollTop: $(hash).offset().top - $('.main-header').height() - 30}, 300);
    };

    if(window.location.hash.length){
      $('html, body').scrollTop(0);
      scrollToHash(window.location.hash);
    }

    $(document).on('click', 'a[href*="#"]', function (e) {
      var base = location.origin+location.pathname;
      var path = this.origin+this.pathname;
      if(base == path && $(this.hash).length) {
        e.preventDefault();
        scrollToHash(this.hash);
      }
    });
  };

  scope.init = function () {
    scope.container = $('body');

    scope.addEvents();
  }();
})();