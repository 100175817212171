;(function(){
  var months = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];

  window.Post = function (type, obj) {
    var _this = this;
    this.id = obj.id;
    this.message = obj.message || obj.text || Object(obj.caption).text || '';
    var bind = this.obj = {type: type, obj: obj};

    var from = (function() {
      switch (bind.type){
        case 'fb':
          return {
            id: bind.obj.from.id,
            name: bind.obj.from.name,
            link: 'https://facebook.com/'+bind.obj.from.id,
            photo: 'https://graph.facebook.com/'+bind.obj.from.id+'/picture?type=normal'
          };
        case 'tw':
          return {
            id: bind.obj.user.id,
            name: bind.obj.user.name,
            link: 'https://twitter.com/'+bind.obj.user.screen_name,
            photo: bind.obj.user.profile_image_url
          };
        case 'ig':
          return {
            id: bind.obj.user.id,
            name: bind.obj.user.full_name,
            link: 'https://instagram.com/'+bind.obj.user.username,
            photo: bind.obj.user.profile_picture
          }
      }
    });

    var date = (function(date_object) {
      date_object = date_object || false;
      var d;
      switch (bind.type) {
        case 'fb':
          d = new Date(bind.obj.created_time);
          break;
        case 'tw':
          d = new Date(bind.obj.created_at);
          break;
        case 'ig':
          d = new Date(bind.obj.created_time*1000);
      }
      if(date_object){
        return d;
      }
      return d.getDate()+' de '+months[d.getMonth()]+' às '+d.getHours()+':'+d.getMinutes();
    });

    this.likes = (function () {
      switch (bind.type){
        case 'fb': return (bind.obj.likes || {data: []}).data.length;
        case 'tw': return bind.obj.favorite_count;
        case 'ig': return bind.obj.likes.count;
      }
    })();

    this.class = (function () {
      switch (bind.type){
        case 'fb': return 'fa-facebook-square';
        case 'tw': return 'fa-twitter-square';
        case 'ig': return 'fa-instagram';
      }
    })();

    this.link = (function () {
      switch (bind.type){
        case 'fb': return 'https://facebook.com/'+_this.id;
        case 'tw': return from().link;
        case 'ig': return bind.obj.link;
      }
      return bind.obj.link;
    })();

    this.origin = (function () {
      switch (bind.type){
        case 'fb': return 'Facebook';
        case 'tw': return 'Twitter';
        case 'ig': return 'Instagram';
      }
    })();

    return {
      id: this.id,
      get img(){
        switch (bind.type){
          case 'fb': return bind.obj.full_picture;
          case 'ig': return bind.obj.images.standard_resolution.url;
          case 'tw':
            var media = bind.obj.entities.media;
            if(!media) return '';
            media = media.filter(function (obj) {
              return obj.type == 'photo';
            });
            if(!media.length) return '';
            return media[0].media_url_https;
        }
        return '';
      },
      get template(){
        return '' +
          '<div class="item loading">' +
            '<a href="'+ from().link +'" class="head">' +
              '<div class="logo" style="background-image: url('+ from().photo +');"></div>' +
                '<div class="desc">' +
                '<div class="title blue">'+ from().name +'</div>' +
                '<div class="date" data-date="'+ date(true).toString() +'">'+ date() +'</div>' +
              '</div>' +
            '</a>' +
            '<div class="content">' +
              (function () {
                if(_this.message){
                  return '<div class="text">' + _this.message + '</div>';
                }
                return '';
              })() +
              (function () {
                if(this.img){
                  return '<img src="'+ this.img +'">'
                }
                return '';
              }).apply(this) +
            '</div>' +
            '<div class="footer">' +
              '<div class="likes fa fa-thumbs-up">'+ _this.likes +' curtidas</div>' +
              '<a href="'+ _this.link +'" class="see-on fa '+ _this.class +'" target="_blank">Ver no '+ _this.origin +'</a>' +
            '</div>' +
          '</div>' +
          ''
      }
    }
  };
})();