;(function(){
	var Page = $('.page-birthdays');

  Page.addEvents = function () {
    Page.find('.birthdays .box .btn.see-more').on('click', function (e) {
      e.preventDefault();

      var btn = $(this);
      var box = btn.closest('.box');

      box.find('.list .hide').removeClass('hide');
      btn.remove();
    });
  };

  Page.init = function () {
    Page.addEvents();
  };

  Page.init();
})();