;(function () {

  var Home = $('main.page-home');

  Home.addEvents = function () {
    Home.slider.owlCarousel({
      items: 1,
      mouseDrag: false,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause:true,
      animateOut: 'fadeOut',
      navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>']
    });
  };

  Home.init = function () {
    Home.slider = Home.find('.main-slider');

    Home.addEvents();
  }()

})();