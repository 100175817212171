;(function () {
    var Header = $('.main-header');

    Header.toggleFixed = function (fixed) {
        fixed = fixed === undefined ? true : fixed;

        Header.toggleClass('relative', !fixed);
    };

    Header.addEvents = function () {
        Header.menu.on('click touchend', '.handler', function (e) {
            e.preventDefault();

            Header.menu.toggleClass('active');
        });
        if (!!('ontouchstart' in window) || !!('onmsgesturechange' in window)) {
            Header.menu.on('click', '.nav li', function (e) {
                if (!$(e.target).is('li') && (window.innerWidth < 1024 || !$(e.target).is('li > a')))
                    return;
                e.preventDefault();
                e.stopImmediatePropagation();
                $(this).closest('.nav li').toggleClass('active');
            });
        }

        $(document).on('click touchend', function (e) {
            var tgt = $(e.target);
            if (!Header.menu.is('.active')) return true;

            if (!tgt.closest('.social, .nav, .handler').length) {
                e.preventDefault();
                Header.menu.removeClass('active');
            }
        });

        $(window).on('scroll resize', function () {
            var $window = $(window);
            var top = $window.scrollTop();
            var height = Header.height();

            Header.toggleFixed(top > height);
        }).trigger('scroll');
    };

    Header.init = function () {
        Header.menu = Header.find('.menu');

        Header.addEvents();
    };

    Header.init();
})();