;(function(){
  var EventGallery = $('.page-gallery, .page-event');
  var Social = EventGallery.find('.social');
  Social.fbToken = Social.data('fb-token');
  Social.clientID = '73a1aa07200e4f718c01e926e05261e5';
  Social.igToken = '2240426723.73a1aa0.a40d5a974bc44b919c2eedb164166a6f';
  Social.finished = {
    fb: false,
    ig: false,
    tw: false
  };
  Social.isFinished = function () {
    return Social.finished.fb && Social.finished.ig && Social.finished.tw;
  };

  Social.sort = function () {
    var map = $.map(Social.find('.list .item'), function (el) {
      return $(el);
    });
    map.sort(function (a, b) {
      var date = {
        a: new Date($(a).find('.date').data('date')),
        b: new Date($(b).find('.date').data('date'))
      };
      return date.a < date.b ? 1 : -1;
    });

    Social.height(Social.height());
    var $column = [];
    $column.push(Social.find('.list .column').eq(0));
    if($(window).width() > 520){
      $column.push(Social.find('.list .column').eq(1));
      if($(window).width() >= 1200){
        $column.push(Social.find('.list .column').eq(2));
      }
    }

    function getSmallest() {
      $column.sort(function (a, b) {
        return a.height() > b.height() ? 1 : -1;
      });
      return $column[0];
    }

    $.each(map, function (i, el) {
      el.appendTo(getSmallest());
    });
    if(Social.isFinished()){
      Social.css('height', 'auto').find('.item').removeClass('loading');
      if(Social._scroll){
          console.log(Social._scroll);
          $('html, body').prop('scrollTop', Social._scroll);
      }
    }
  };

  EventGallery.addEvents = function () {
    EventGallery.photos.on('click', function (e) {
      e.preventDefault();

      var btn = $(this);
      var map = $.map(btn.find('[data-photo]'), function (el) {
        return {
          href: $(el).data('photo'),
          title: $(el).html()
        }
      });

      $.fancybox.open(map, {
        helpers:{
          thumbs: {
            width: 80,
            height: 80
          }
        }
      });
    });

    EventGallery.videos.on('click', function (e) {
      e.preventDefault();

      var btn = $(this);
      var map = $.map(btn.find('[data-video]'), function (el) {
        return {
          href: $(el).data('video'),
          title: $(el).html()
        }
      });

      $.fancybox.open(map, {
        helpers: {
          media: {}
        }
      });
    });

    $(window).on('resize', Social.sort);
  };

  Social.add = function (template) {
      var list = Social.find('.list');
      list.append(template);
      if(Social._scroll){
        $('html, body').prop('scrollTop', Social._scroll);
      }
  };

  Social.on('click', '.load-more-feed',function (e) {
    e.preventDefault();
    if($(this).is('.loading')) return true;

    $(this).addClass('loading');
    Social.nextFeed();
  });

  Social.nextFeed = function () {
    Social._scroll = $(document).scrollTop();
    console.log(Social._scroll);
    Social.finished = {
      fb: false,
      ig: false,
      tw: false
    };
    Social.getFBFeed(Social.fbNext);
    Social.getTWFeed();
    Social.getIGFeed();
  };

  Social.getFBFeed = function (url) {
    url = url || '/161417670551646/posts?access_token='+Social.fbToken;

    FB.api(url, 'get', {
        limit: 5,
        fields: "full_picture,id,message,from,created_time,likes"
      },
      function (res) {
        if(res.error){
          console.error(res.error);
          return false;
        }
        var posts = res.data.map(function (el) {
          return new Post('fb', el);
        });
        Social.fbNext = res.paging.next;
        $.each(posts, function (i,post) {
          Social.add(post.template);
        });
        Social.finished.fb = true;
        Social.sort();
        var btnLoad = Social.find('.load-more-feed');
        btnLoad.removeClass('loading');
      });
  };

  Social.getTWFeed = function () {
    var params = {
      url: site_config.base_url+'twitter-feed',
      type: 'get',
      dataType: 'json'
    };
    if(Social.twMaxId){
      params.data = {max_id: Social.twMaxId};
    }
    $.ajax(params).done(function (res) {
      if(!res) return true;
      var posts = res.map(function (el) {
        Social.twMaxId = el.id;
        return new Post('tw', el);
      });
      $.each(posts, function (i,post) {
        Social.add(post.template);
      });
    }).always(function () {
      Social.finished.tw = true;
      Social.sort();
    });
  };

  Social.getIGFeed = function () {
    var params = {
      url: site_config.base_url+'instagram-feed',
      type: 'get',
      dataType: 'json'
    };
    if(Social.igMaxId){
      params.data = {max_id: Social.igMaxId};
    }
    $.ajax(params).done(function (res) {
      if(!res.data) return true;
      var posts = res.data.map(function (el) {
        return new Post('ig', el);
      });
      $.each(posts, function (i,post) {
        Social.add(post.template);
      });
      Social.igMaxId = res.pagination.next_max_id;
    }).always(function () {
      Social.finished.ig = true;
      Social.sort();
    });
  };


  EventGallery.init = function () {
    EventGallery.photos = EventGallery.find('.actions-bar .see-photos');
    EventGallery.videos = EventGallery.find('.actions-bar .see-videos');

    EventGallery.addEvents();

    if(Social.length){
      $.ajaxSetup({ cache: true });
      $.getScript('//connect.facebook.net/pt_BR/sdk.js', function(){
        FB.init({
          appId: '341810369527246',
          cache: true,
          xfbml: true,
          version: 'v2.8'
        });
        Social.getFBFeed();
      });
      Social.getTWFeed();
      Social.getIGFeed();
    }
  };

  EventGallery.init();
})();