(function ($) {
    $.fn.getPath = function () {
        if (this.length != 1) throw 'Requires one element.';

        var path, node = this;
        while (node.length) {
            var realNode = node[0], name = realNode.localName;
            if (!name) break;
            name = name.toLowerCase();

            var parent = node.parent();

            var siblings = parent.children(name);
            if (siblings.length > 1) {
                name += ':eq(' + siblings.index(realNode) + ')';
            }

            path = name + (path ? '>' + path : '');
            node = parent;
        }

        return path;
    };

    $.fn.getQueryString = function ($var) {
        function escapeRegExp(str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        }
        var regex = new RegExp(escapeRegExp($var)+'=([^&]*)');
        var matches = decodeURI(location.search.substring(1)).match(regex);

        if(matches != null && matches.length > 1){
            return matches[1];
        }
        return null;
    };

    $.fn.comments = function () {
        if(this.length != 1) throw "Requires one element...";

        return this.contents().filter(function(i, el){ return el.nodeType == 8 });
    };

    $.fn.sortElements = (function(){

        var sort = [].sort;

        return function(comparator, getSortable) {

            getSortable = getSortable || function(){return this;};

            var placements = this.map(function(){

                var sortElement = getSortable.call(this),
                  parentNode = sortElement.parentNode,

                  // Since the element itself will change position, we have
                  // to have some way of storing its original position in
                  // the DOM. The easiest way is to have a 'flag' node:
                  nextSibling = parentNode.insertBefore(
                    document.createTextNode(''),
                    sortElement.nextSibling
                  );

                return function() {

                    if (parentNode === this) {
                        throw new Error(
                          "You can't sort elements if any one is a descendant of another."
                        );
                    }

                    // Insert before flag:
                    parentNode.insertBefore(this, nextSibling);
                    // Remove flag:
                    parentNode.removeChild(nextSibling);

                };

            });

            return sort.call(this, comparator).each(function(i){
                placements[i].call(getSortable.call(this));
            });

        };

    })();
})(jQuery);

window.clone = function (dest, copyFrom) {
    var copy = dest;
    for (var i in copyFrom){
        copy[i] = copyFrom[i];
    }
    return copy;
};