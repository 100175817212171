;(function(){
	window.Alert = function (msg, options) {
	  options = options || {};
    options = {
	    type: options.type || null,
      time: options.time || 12
    };
    console.log(options);
    var container = $('body');
    container
      .removeAttr('data-alert')
      .removeAttr('data-alert-duration');
    setTimeout(function () {
      container
        .attr('data-alert', msg)
        .removeClass('alert-success alert-error');
      if(options.type)
        container.addClass('alert-'+options.type);

      if(options.time)
        container.attr('data-alert-duration', options.time);
    },50);
  };
})();